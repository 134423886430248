import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import get from 'lodash/get'
import React from 'react'
import Helmet from 'react-helmet'
import Footer from '../components/footer'
import heroStyles from '../components/hero.module.css'
import Layout from '../components/layout'
import RichText from '../components/rich-text'
import Disqus from '../components/shared/Disqus'
import WideRecipePreview from '../components/WideRecipePreview'
import YouTubeVideo from '../components/YouTubeVideo'
import styles from './recipe.module.css'

const formatTime = min => {
  const hours = min / 60 >= 1 ? `${Math.floor(min / 60)} hr ` : ''
  const minutes = min % 60 > 0 ? `${min % 60} min` : ''

  return `${hours}${minutes}`
}

class RecipeTemplate extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      tab: 'method',
    }
  }

  showMethod() {
    this.setState({ tab: 'method' })
  }

  showStory() {
    this.setState({ tab: 'story' })
  }

  showTips() {
    this.setState({ tab: 'tips' })
  }

  render() {
    const post = get(this.props, 'data.contentfulRecipe')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const tips = get(post, 'tips')

    const { issue } = post
    const sisterRecipes = get(post, 'issue.recipes')
      ? issue.recipes.filter(r => r.slug !== post.slug)
      : []

    const tab = this.state.tab

    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#fff' }}>
          <Helmet title={`${post.title} | ${siteTitle}`}>
            <meta
              property="og:title"
              content={`${post.title} | ${siteTitle}`}
            />
            <meta property="og:type" content="website" />
            <meta property="og:description" content={post.tagline.tagline} />
            <meta property="og:image" content={post.photo.fluid} />
            <meta property="og:image:width" content="1600" />
            <meta property="og:image:height" content="900" />
            <meta
              property="og:url"
              content={`https://journeyeast.co/recipe/${post.slug}`}
            />
          </Helmet>
          <div className={styles.header}>
            <h1 className={styles.recipeTitle}>{post.title}</h1>
            {post.hanzi && <h1 className={styles.hanziTitle}>{post.hanzi}</h1>}
            <p className={styles.tagline}>{post.tagline.tagline}</p>
          </div>
          <div className={heroStyles.hero}>
            <Img
              className={heroStyles.heroImage}
              alt={post.title}
              fluid={post.photo.fluid}
            />
          </div>
          <div className="wrapper">
            <div className={styles.content}>
              <div className={styles.leftColumn}>
                <div className={styles.recipeBox}>
                  <h3 className={styles.recipeBoxHeader}>Ingredients</h3>
                  <RichText field={post.ingredients} />
                </div>
              </div>
              <div className={styles.mainColumn}>
                <div className={styles.tabs}>
                  <button
                    onClick={this.showMethod.bind(this)}
                    className={tab === 'method' ? styles.selected : ''}
                  >
                    Method
                  </button>
                  <button
                    onClick={this.showStory.bind(this)}
                    className={tab === 'story' ? styles.selected : ''}
                  >
                    Story
                  </button>
                  <button
                    onClick={this.showTips.bind(this)}
                    className={tab === 'tips' ? styles.selected : ''}
                  >
                    Tips
                  </button>
                </div>
                <div className={styles.mainBox}>
                  {tab === 'method' && (
                    <div className={styles.method}>
                      <div className={styles.metadata}>
                        <p>
                          <span className={styles.highlight}>Course</span>
                          {post.course}
                        </p>
                        <p>
                          <span className={styles.highlight}>Makes</span>
                          {post.makes}
                        </p>
                        <p>
                          <span className={styles.highlight}>Active Time</span>
                          {formatTime(post.activeTime)}
                        </p>
                        <p>
                          <span className={styles.highlight}>Total Time</span>
                          {formatTime(post.totalTime)}
                        </p>
                      </div>
                      {post.youTubeId && (
                        <YouTubeVideo videoId={post.youTubeId} />
                      )}
                      <div className={styles.steps}>
                        <RichText field={post.steps} />
                      </div>
                    </div>
                  )}
                  {tab === 'story' && (
                    <div className={styles.story}>
                      <RichText field={post.description} />
                    </div>
                  )}
                  {tab === 'tips' && (
                    <div className={styles.tips}>
                      <RichText field={post.tips} />
                    </div>
                  )}
                </div>
                {issue && (
                  <div className={styles.zineFeature}>
                    <p className={styles.featureText}>
                      This recipe was featured in the weekly menu of{' '}
                      <Link to={`/zine/${issue.number}`}>
                        Issue #{issue.number}
                      </Link>{' '}
                      of the Journey East Zine
                      {sisterRecipes.length > 0 ? ' alongside:' : '.'}
                    </p>
                    <div className={styles.sisterRecipes}>
                      {sisterRecipes
                        .filter(r => r.slug !== post.slug)
                        .map(r => (
                          <WideRecipePreview
                            recipe={r}
                            className={styles.recipePreview}
                          />
                        ))}
                    </div>
                  </div>
                )}
                <Disqus
                  identifier={`recipe-${post.slug}`}
                  title={post.title}
                  width="100%"
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Layout>
    )
  }
}

export default RecipeTemplate

export const pageQuery = graphql`
  query RecipeBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulRecipe(slug: { eq: $slug }) {
      title
      slug
      hanzi
      photo {
        fluid {
          srcSet
        }
      }
      publishDate(formatString: "MMMM Do, YYYY")
      tagline {
        tagline
      }
      youTubeId
      course
      description {
        json
      }
      makes
      activeTime
      totalTime
      ingredients {
        json
      }
      steps {
        json
      }
      tips {
        json
      }
      issue {
        number
        title
        recipes {
          title
          slug
          hanzi
          photo {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid
            }
          }
          tagline {
            tagline
          }
        }
      }
    }
  }
`
